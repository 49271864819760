/**
 * @class
 */
export default class Link {
    /**
     * @constructor
     * @param empty
     * @param externalUrl
     * @param hotelId
     * @param newWindow
     * @param pageId
     * @param productId
     * @param searchTagAndMode
     * @param searchTags
     * @param valueVoucherId
     */
    constructor({
        empty,
        externalUrl,
        hotelId,
        newWindow,
        pageId,
        productId,
        searchTagAndMode,
        searchTags,
        valueVoucherId
    } = {}) {
        this.productId = productId;
        this.valueVoucherId = valueVoucherId;
        this.hotelId = hotelId;
        this.page = pageId;
        this.search = searchTags;
        this.url = externalUrl;
        this.newWindow = newWindow;
        this.searchTagAndMode = searchTagAndMode;
        this.empty = !productId && !hotelId && !pageId && !valueVoucherId && !externalUrl;

        this.webnames = [];
        this.queryString = {};
    }

    export() {
        const linkToExport = {
            ...this,
            pageId: this.page,
            searchTags: [...(this.search || [])],
            externalUrl: this.url
        };

        delete linkToExport.page;
        delete linkToExport.webnames;
        delete linkToExport.queryString;
        delete linkToExport.search;
        delete linkToExport.url;
        delete linkToExport.empty;

        return linkToExport;
    }
}
