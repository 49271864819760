import ContentType from './ContentType';
import ContentTypes from './ContentTypes';
import Image from '../Image';
import StaticLink from '../StaticLink';

export class Position {
    constructor({ top, right, bottom, left }) {
        this._top = top || 0;
        this._right = right || 0;
        this._bottom = bottom || 0;
        this._left = left || 0;
    }

    get top() {
        return this._top;
    }
    set top(value) {
        this._top = this.calculatePosition(value);
    }

    get left() {
        return this._left;
    }
    set left(value) {
        this._left = this.calculatePosition(value);
    }

    get right() {
        return this._right;
    }
    set right(value) {
        this._right = this.calculatePosition(value);
    }

    get bottom() {
        return this._bottom;
    }
    set bottom(value) {
        this._bottom = this.calculatePosition(value);
    }

    calculatePosition(value) {
        return Math.max(Math.min(value, 100), 0);
    }

    toJSON() {
        return {
            ...this,
            top: this.top,
            right: this.right,
            bottom: this.bottom,
            left: this.left
        };
    }
}

/**
 * @class
 */
export default class BannerContentType extends ContentType {
    /**
     * @constructor
     * @param {Object} obj
     * @param {Image} obj.image
     * @param {Link} [obj.link]
     * @param {string} [obj.htmlContent]
     * @param {Position} [obj.htmlPosition]
     * @param {...object} [obj.rest]
     */
    constructor({ image = {}, link = {}, htmlContent, htmlPosition, ...rest }) {
        super({
            type: ContentTypes.Banner,
            ...rest
        });

        this.link = new StaticLink(link);
        this.image = new Image(image);
        this.htmlContent = htmlContent;
        this.htmlPosition = htmlPosition ? new Position(htmlPosition) : new Position();
    }

    export() {
        return {
            ...this.toJSON(),
            link: this.link.export()
        };
    }
}
