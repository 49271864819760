<template>
    <details
        class="collapsible-item"
        @toggle="toggleOpen"
        :class="{
            'collapsible-item--open': open,
            'collapsible-item--nested': nested,
            'collapsible-item--filled': filledTitle
        }"
    >
        <summary class="collapsible-item__summary">
            <span class="collapsible-item__safari-sux">
                <slot v-if="prependIcon" name="summary.prepend-icon">
                    <IconSvg filepath="add.svg" class="collapsible-item__icon collapsible-item__icon-prepend" />
                </slot>

                <slot name="summary.prepend"></slot>

                <strong class="collapsible-item__title">
                    <slot name="summary.title"></slot>
                </strong>

                <slot name="summary.append"></slot>

                <slot v-if="appendIcon" name="summary.append-icon" :open="open">
                    <i class="collapsible-item__icon">&#10095;</i>
                </slot>
            </span>
        </summary>

        <div class="collapsible-item__content" ref="content">
            <slot name="expandable"> </slot>
        </div>
    </details>
</template>

<script>
export default {
    name: 'CollapsibleItem',

    props: {
        /**
         * sets open state. Optional, thus collapsible can toggle without any props
         */
        open: Boolean,
        prependIcon: Boolean,
        appendIcon: Boolean,
        /**
         * Removes the borders and paddings
         */
        nested: Boolean,
        /**
         * Fills headings with border color
         */
        filledTitle: Boolean
    },

    methods: {
        toggleOpen(e) {
            this.$emit('update:open', e.target.open);
            this.updateValue(e.target.open);
        },
        updateValue(open) {
            // Vue 2 does not support boolean html open attribute binding. Manipulating DOM instead
            if (open) {
                this.$refs.content.style.display = ''; // attempt to fix Google Search Console "video too small indexing problem"
                this.$el.classList.add('collapsible-item--open');

                // Timeout postpones open attribute as it will immediately reveal the block with no animation
                setTimeout(() => {
                    this.$el.setAttribute('open', '');
                }, 200);
            } else {
                this.$el.classList.remove('collapsible-item--open');
                setTimeout(() => {
                    this.$el.removeAttribute('open');
                    if (this.$refs.content) {
                        this.$refs.content.style.display = 'none'; // attempt to fix Google Search Console "video too small indexing problem"
                    }
                }, 200);
            }
        }
    },

    mounted() {
        this.updateValue(this.open);
    },

    watch: {
        open(value) {
            this.updateValue(value);
        }
    }
};
</script>

<style scoped lang="scss">
@use '@/styles/variables';
@use '@/styles/mixins';
@use 'sass:map';

$collapsible-padding: 0.75em;

.collapsible-item {
    overflow: hidden;

    &:not(:last-child) {
        margin-bottom: 10px;
    }

    &__summary {
        position: relative;
        padding: $collapsible-padding;
        margin-bottom: 0;
        border: 1px solid variables.$border-color;
        cursor: pointer;
        display: block;

        @include mixins.mobile-only {
            min-height: 48px; //mobile usability
        }

        &::-webkit-details-marker,
        &::marker {
            display: none;
        }

        &:focus {
            outline: none;
        }
    }

    // Do get me wrong, but older Safari does not treat flex applied to summary elements
    &__safari-sux {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        justify-content: space-between;
    }

    &__title {
        flex: 1;

        ::v-deep > * {
            margin-bottom: 0 !important;
        }
    }

    &__icon {
        width: 1em;
        height: 1em;
        text-align: center;
        transition: all 0.3s;
        line-height: 1em;
        font-style: normal;
    }

    &__icon,
    &__content {
        transition: all 0.2s ease-in-out; // need to be all, otherwise some animations will fail
    }

    &__icon-prepend {
        font-size: 1.2em;
        margin-right: 10px;
        color: var(--primary);
    }

    &__icon-append {
        font-size: 1.5em;
        margin-left: 10px;
        color: var(--inputs-empty-color);
    }

    &__content {
        max-height: 0;
        padding: 0 $collapsible-padding;
        overflow: hidden;
        opacity: 0;
        //display: none;
    }

    &__content ::v-deep {
        p:last-child {
            margin-bottom: 0;
        }
    }

    $self: &;
    &--open {
        //BEM modifier--open cannot be used, as open prop might not be provided
        #{ $self }__summary {
            border-color: map.get(variables.$inputs, 'filled-color');
        }

        #{ $self }__icon {
            transform: rotate(90deg);
        }

        #{ $self }__icon-prepend {
            transform: rotate(135deg);
        }

        #{ $self }__content {
            max-height: fit-content;
            border: 1px solid variables.$border-color;
            border-top-width: 0;
            padding: $collapsible-padding;
            opacity: 1;
        }
    }

    &--nested {
        #{ $self }__summary {
            border: none;
            padding: 0;
        }

        #{ $self }__content {
            border: none;
            padding: $collapsible-padding 0 0;
        }
    }

    &--filled {
        #{ $self }__summary {
            border: none;
            background-color: var(--border-color);

            &:hover {
                background-color: color-mix(in srgb, var(--border-color), black 5%);
            }
        }

        #{ $self }__content {
            border: none;
        }
    }
}
</style>
