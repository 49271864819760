export const Alignment = {
    left: 'left',
    center: 'center',
    right: 'right'
};

export default [
    { text: 'Linksbündig', value: Alignment.left },
    { text: 'Zentriert', value: Alignment.center },
    { text: 'Rechtsbündig', value: Alignment.right }
];
