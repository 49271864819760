<template>
    <div class="accordion" ref="accordion">
        <CollapsibleItem
            v-for="(item, index) in data.items"
            :key="'accordionItem' + index"
            :open="isOpen(index)"
            :append-icon="!prependIcons"
            :prepend-icon="prependIcons"
            :filled-title="filledTitles"
            :nested="nested"
            @update:open="setOpen($event, index)"
        >
            <template v-slot:summary.title>
                <slot :name="'item[' + index + '].summary.title'">
                    {{ item.title }}
                </slot>
            </template>

            <template v-slot:summary.append-icon>
                <slot :name="'item[' + index + '].summary.append-icon'"></slot>
            </template>

            <template v-slot:expandable>
                <slot :name="'item[' + index + '].expandable'">
                    <div v-html="item.text"></div>
                </slot>
            </template>
        </CollapsibleItem>
    </div>
</template>

<script>
import AccordionContentType from 'cms/models/contentTypes/Accordion';
import CollapsibleItem from '@/components/utility/CollapsibleItem';

export default {
    name: 'Accordion',

    components: { CollapsibleItem },

    data() {
        return {
            openIndex: -1,
            openIndices: []
        };
    },

    props: {
        data: {
            type: [AccordionContentType, Object],
            required: true
        },

        keepOpened: Boolean,

        filledTitles: Boolean,

        prependIcons: Boolean,

        nested: Boolean
    },

    methods: {
        isOpen(index) {
            if (this.keepOpened) {
                return !!~this.openIndices.indexOf(index);
            }
            return this.openIndex === index;
        },

        setOpen(value, index) {
            if (value) {
                if (this.keepOpened) {
                    this.openIndices = [...this.openIndices, index].sort((a, b) => a - b);
                } else {
                    this.openIndex = index;
                }
            } else {
                if (this.keepOpened) {
                    this.openIndices.splice(index, 1);
                } else if (index === this.openIndex) {
                    this.openIndex = -1;
                }
            }
        }
    }
};
</script>

<style scoped lang="scss">
.accordion {
    display: flex;
    font-weight: 400;
    overflow: hidden;
    flex-direction: column;
    color: inherit;
    padding-bottom: 3px;
}
</style>
