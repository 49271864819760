<template>
    <div class="content-types">
        <template v-for="(t, index) in data">
            <template v-if="t.style">
                <div v-html="getStyle(t)"></div>
            </template>

            <hr v-if="t.hrTop" :key="'hrTop' + index" />

            <div class="content-types__item row">
                <component
                    :key="'contentType' + index"
                    :is="getTypeComponent(t.type)"
                    :data="t"
                    :class="getColumnClasses(t)"
                />
            </div>

            <hr v-if="t.hrBottom" :key="'hrBottom' + index" />
        </template>
    </div>
</template>

<script>
import ContentTypes from 'cms/models/contentTypes/ContentTypes';

export default {
    name: 'ContentTypes',

    components: {
        ImageText: () => import(/* webpackChunkName: "contentTypes" */ '@/components/grid/ImageTextContentType'),
        Accordion: () => import(/* webpackChunkName: "contentTypes" */ '@/components/grid/Accordion'),
        Banner: () => import(/* webpackChunkName: "contentTypes" */ '@/components/grid/BannerContentType'),
        Container: () => import(/* webpackChunkName: "contentTypes" */ '@/components/grid/ContainerContentType')
    },

    data() {
        return {};
    },

    props: {
        data: {
            type: Array,
            required: true
        }
    },

    methods: {
        getTypeComponent(type) {
            switch (type) {
                case ContentTypes.ImageText:
                    return 'ImageText';
                case ContentTypes.Accordion:
                    return 'Accordion';
                case ContentTypes.Banner:
                    return 'Banner';
                case ContentTypes.Container:
                    return 'Container';
            }
        },

        getStyle(t) {
            return `<style>${t.style}</style>`;
        },

        getColumnClasses(type) {
            return [type.classNames, `col-lg-${type.columnsDesktop}`, `offset-lg-${type.offsetDesktop}`];
        }
    }
};
</script>

<style scoped lang="scss">
.content-types__item:not(:last-child) {
    margin-bottom: 15px;
}
</style>
