<template>
    <component :is="component" class="banner" :link="link" maskTag="div" v-ripple>
        <div class="banner__wrapper" :style="{ height: data.image.height + 'px' }">
            <ImageFluid
                v-if="data.image.src"
                :src="data.image.src"
                :alt="data.image.alt"
                :title="data.image.title"
                :width="data.image.width"
                :height="data.image.height"
                class="banner__image"
            />

            <template v-if="data.htmlContent">
                <div v-html="data.htmlContent" class="banner__html" :style="position"></div>
            </template>
        </div>
    </component>
</template>

<script>
import BannerContentType from 'cms/models/contentTypes/Banner';
import ImageFluid from '@/components/ImageFluid';
import ripple from '@/directives/ripple';

export default {
    name: 'BannerContentType',

    components: { ImageFluid },
    directives: { ripple },
    data() {
        return {};
    },

    props: {
        data: {
            type: [Object, BannerContentType],
            required: true
        }
    },

    computed: {
        hasLink() {
            return !!Object.keys(this.data.link).length;
        },
        link() {
            return this.hasLink ? this.data.link || {} : undefined;
        },
        component() {
            return this.hasLink ? 'Link' : 'div';
        },
        top() {
            return this.data.htmlPosition.top ? this.data.htmlPosition.top + '%' : undefined;
        },
        right() {
            return this.data.htmlPosition.right ? this.data.htmlPosition.right + '%' : undefined;
        },
        bottom() {
            return this.data.htmlPosition.bottom ? this.data.htmlPosition.bottom + '%' : undefined;
        },
        left() {
            return this.data.htmlPosition.left ? this.data.htmlPosition.left + '%' : undefined;
        },
        position() {
            const { top, right, bottom, left } = this;
            return { top: top, right: right, bottom: bottom, left: left };
        }
    }
};
</script>

<style scoped lang="scss">
.banner {
    &__wrapper {
        position: relative;
        display: block;
    }

    &__image {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
    }

    &__html {
        z-index: 2;
        position: absolute;
        overflow: hidden;
    }
}
</style>
