/**
 * @class
 */
export default class Image {
    /**
     * @constructor
     * @param {string} src
     * @param {string} alt
     * @param {string} [width]
     * @param {string }[height]
     * @param {string} [title]
     */
    constructor({ src, alt = '', width, height, title = '' }) {
        Object.assign(this, { src, alt, width, height, title });
    }
}
