import ContentTypes from './ContentTypes';

/**
 * @class
 */
export default class ContentType {
    /*
        Do not use private members, e.g. #columnsDesktop. They are unstable
     */

    /**
     * @constructor
     * @param {object} obj
     * @param {ContentTypes} obj.type,
     * @param {Boolean} [obj.hrTop]
     * @param {Boolean} [obj.hrBottom]
     * @param {string} [obj.style]
     * @param {string} [obj.classNames]
     * @param {number} [obj.columnsDesktop]
     * @param {number} [obj.offsetDesktop]
     * @param {number} [obj.sortOrder]
     */
    constructor({
        type,
        hrTop = false,
        hrBottom = false,
        style,
        classNames,
        sortOrder,
        columnsDesktop = 12,
        offsetDesktop = 0
    }) {
        this.type = type;

        // each content type can have a hr
        this.hrTop = hrTop;
        this.hrBottom = hrBottom;

        this.style = style;
        this.classNames = classNames;

        this._offsetDesktop = offsetDesktop;
        this._columnsDesktop = columnsDesktop;

        this.sortOrder = sortOrder;
    }

    static colsArray = Array.from({ length: 12 }, (_, index) => index + 1);

    get columnsDesktop() {
        return this._columnsDesktop;
    }
    set columnsDesktop(value) {
        this._columnsDesktop = Math.min(value, 12);
    }

    get offsetDesktop() {
        return this._offsetDesktop;
    }
    set offsetDesktop(value) {
        this._offsetDesktop = Math.min(12 - this.columnsDesktop, value);
    }

    toJSON() {
        const { _offsetDesktop, _columnsDesktop, ...rest } = this;
        return {
            ...rest,
            columnsDesktop: this.columnsDesktop,
            offsetDesktop: this.offsetDesktop
        };
    }
}
