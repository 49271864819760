<template>
    <div class="container-content">
        <div class="row">
            <div
                v-for="(col, index) in data.columns"
                :key="'col' + index"
                :class="['col-' + col.colsMobile, 'col-lg-' + col.colsDesktop]"
            >
                <div v-html="col.htmlContent" class="container-content__column"></div>
            </div>
        </div>
    </div>
</template>

<script>
import ContainerContentType from 'cms/models/contentTypes/Container';

export default {
    name: 'ContainerContentType',
    data() {
        return {};
    },

    props: {
        data: {
            type: [ContainerContentType, Object],
            required: true
        }
    },

    computed: {}
};
</script>

<style scoped lang="scss">
.container-content__column {
    min-height: 50px;
}
</style>
