<template>
    <div class="image-text">
        <div class="row">
            <div v-if="image.src" class="col-12" :class="[imageBlockClass, imageOrderClass]">
                <ImageFluid
                    :src="image.src"
                    :alt="image.alt"
                    :width="image.width"
                    :height="image.height"
                    :style="{ height: imageHeight }"
                    :title="image.title"
                    class="image-text__image"
                />
            </div>

            <div class="col" v-html="data.htmlContent"></div>

            <template v-if="data.secondaryHtmlContent && isImageCentered">
                <div class="col order-last" v-html="data.secondaryHtmlContent"></div>
            </template>
        </div>
    </div>
</template>

<script>
import ImageTextContentType from 'cms/models/contentTypes/ImageText';
import { Alignment } from 'cms/models/AlignmentModes';
import ImageFluid from '@/components/ImageFluid';

export default {
    name: 'ImageTextContentType',
    components: { ImageFluid },

    props: {
        data: {
            type: [ImageTextContentType, Object],
            required: true
        }
    },

    computed: {
        image() {
            return this.data.image;
        },

        imageBlockClass() {
            return `col-md-${this.data.imageCols}`;
        },

        imageHeight() {
            return !!this.image.height || this.image.height !== 200 ? `${this.image.height}px` : 'auto';
        },

        isImageCentered() {
            return this.data.alignment === Alignment.center;
        },

        imageOrderClass() {
            switch (this.data.alignment) {
                case Alignment.left:
                    return 'order-first';
                case Alignment.center:
                    return 'order-2';
                case Alignment.right:
                    return 'order-last';
            }
        }
    }
};
</script>

<style scoped lang="scss">
.image-text {
    &__image {
        display: block;
    }
}
</style>
