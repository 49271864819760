import ContentType from './ContentType';
import ContentTypes from './ContentTypes';

/**
 * @class
 */
export class Column {
    /**
     * @constructor
     * @param {Object} [obj]
     * @param {string} [obj.htmlContent]
     * @param {number} [obj.colsDesktop]
     * @param {number} [obj.colsMobile]
     */
    constructor({ htmlContent, colsDesktop = 4, colsMobile = 12 } = {}) {
        this.htmlContent = htmlContent;
        this.colsDesktop = colsDesktop;
        this.colsMobile = colsMobile;
        this.offsetDesktop = 0;
        this.offsetMobile = 0;
    }

    setColumns(cols) {
        return Math.min(cols, 12) && Math.max(cols, 1);
    }

    get colsDesktop() {
        return this._colsDesktop;
    }
    set colsDesktop(cols) {
        this._colsDesktop = this.setColumns(cols);
    }

    get colsMobile() {
        return this._colsMobile;
    }
    set colsMobile(cols) {
        this._colsMobile = this.setColumns(cols);
    }

    toJSON() {
        return {
            ...this,
            colsDesktop: this.colsDesktop,
            colsMobile: this.colsMobile
        };
    }
}
/**
 * @class
 */
export default class ContainerContentType extends ContentType {
    /**
     * @constructor
     * @param {Object} [obj]
     * @param {Column[]} [obj.columns]
     * @param {...object} [obj.rest]
     */
    constructor({ columns = [], ...rest } = {}) {
        super({
            type: ContentTypes.Container,
            ...rest
        });

        this.columns = this.instantiateColumns(columns);
    }

    instantiateColumns(columns) {
        return columns.map(c => {
            if (c instanceof Column) {
                return c;
            }
            return new Column(c);
        });
    }

    addEmptyColumns(amountOfCols = 3) {
        for (let i = 0; i < amountOfCols; i++) {
            this.addColumn();
        }
    }

    addColumn(column) {
        const newColumn = new Column(column);
        this.columns.push(newColumn);
        return newColumn;
    }

    /**
     * @param {number} index
     */
    removeColumn(index) {
        this.columns.splice(index, 1);
    }
}
