import Link from './Link';

/**
 * @class
 * @extends Link
 */
export default class StaticLink extends Link {
    constructor(args) {
        super(args);

        this.pageUrl = args.page;
    }

    export() {
        const exportedLink = super.export();

        exportedLink.page = this.pageUrl;
        delete exportedLink.pageUrl;
        return exportedLink;
    }
}
