import ContentType from './ContentType';
import ContentTypes from './ContentTypes';

/**
 * @class
 */
export class AccordionItem {
    /**
     * @constructor
     * @param {object} obj
     * @param {string} obj.title
     * @param {string} obj.text
     */
    constructor({ title, text }) {
        this.title = title;
        this.text = text;
    }
}

/**
 * @class
 */
export default class AccordionContentType extends ContentType {
    /**
     * @constructor
     * @param {Object} obj
     * @param {AccordionItem[]} [obj.items]
     * @param { Boolean } [collapsible]
     * @param {...object} [obj.rest]
     */
    constructor({ items = [], collapsible, ...rest }) {
        super({
            type: ContentTypes.Accordion,
            collapsible,
            ...rest
        });

        this.items = items.map(i => new AccordionItem(i));
    }

    addItem(newTitle, newText) {
        const length = this.items.length;
        const title = newTitle || `Titel ${length}`;
        const text = newText || `Text ${length}`;
        this.items.push(new AccordionItem({ title, text }));
    }

    removeItem(index) {
        const i = Number.isNaN(index) ? this.items.length - 1 : index;
        this.items.splice(i, 1);
    }
}
