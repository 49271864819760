/**
 * @class
 */
import { Alignment } from '../AlignmentModes';
import ContentType from './ContentType';
import ContentTypes from './ContentTypes';
import Image from '../Image';

export default class ImageTextContentType extends ContentType {
    /**
     * @constructor
     * @param {Object} obj
     * @param {Image} obj.image
     * @param {string} obj.htmlContent
     * @param {number} [obj.imageCols]
     * @param {string} [obj.alignment]
     * @param {string} [obj.secondaryHtmlContent]
     */
    constructor({ image = {}, htmlContent, imageCols = 4, alignment = Alignment.left, secondaryHtmlContent, ...rest }) {
        super({
            type: ContentTypes.ImageText,
            ...rest
        });

        this.image = new Image(image);
        this.htmlContent = htmlContent;
        this.imageCols = imageCols;
        this.alignment = alignment;
        this.secondaryHtmlContent = secondaryHtmlContent;
    }
}
